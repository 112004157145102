/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 11/10/2023
 * @description The `ClaimsTable` component displays a table of claims data, allowing users to view, edit, and create new claims. It provides pagination and filtering options.
 */
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Link, useNavigate} from "react-router-dom";
import ActionsOverlayMenu from "../ActionsOverlayMenu/ActionsOverlayMenu";
import {formatDateUtil} from "../../utils/formatDate.util";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import { Calendar } from "primereact/calendar";
import { claimStatusOptions } from "../../consts/claimStatus.const";
import FilterDropdownTemplate from "../../templates/FilterDropdownTemplate";

export default function ClaimsTable({
                                        data, policyInsurance, policyUser,
                                        policy, onLazy, lazyTable, isCreationDisabled
                                    }) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const PoliciesActions = (rowData) => {
        const items = [
            {
                label: t("See"),
                icon: (
                    <span className="me-4 material-symbols-outlined">visibility</span>
                ),
                command: () => navigate(policy ? "/policies/" + policy.id + "/claims/" + rowData.id : "/claims/" + rowData.id),
            },
            {
                label: t("Edit"),
                icon: <span className="me-4 material-symbols-outlined">edit</span>,
                command: () => navigate(policy ? "/policies/" + policy.id + "/claims/" + rowData.id + "/edit" : "/claims/" + rowData.id + "/edit"),
            },
        ];
        return <ActionsOverlayMenu items={items}/>;
    };

    const monthYearFilterTemplate = (options) => {
        return (
            <Calendar
                value={options.value}
                onChange={(e) => options.filterApplyCallback(e.value)}
                view="month"
                dateFormat="yy-mm"
                placeholder={t("Select Month and Year")}
                showButtonBar
            />
        );
    };

    const policyTemplate = (rowData) => {
        return (
            <>
                <Link to={"/claims/" + rowData.id}>{rowData.ourReference}</Link>
            </>
        );
    };

    const tableHeader = () => (
        <div className={"d-flex justify-content-end"}>
            <Link to={"./claims/new"}>
                <Button label={t("Create claim")}></Button>
            </Link>
        </div>
    );


    return <DataTable
        emptyMessage={t("No results found")}
        header={isCreationDisabled ? null : tableHeader}
        value={data}
        filterDisplay="row"
        dataKey="id"
        paginator
        totalRecords={lazyTable.totalRecords}
        rows={lazyTable.rows}
        onPage={onLazy}
        onFilter={onLazy}
        onSort={onLazy}
        lazy
        first={lazyTable.first}
        sortOrder={lazyTable.sortOrder}
    >
        {/*closeDate*/}
        <Column
            field="id"
            filterMatchMode="contains"
            filter
            filterPlaceholder={t("Search")}
            header={t("ID")}
        />
        <Column
            field="ourReference"
            filterMatchMode="contains"
            filter
            filterPlaceholder={t("Search")}
            body={policyTemplate}
            header={t("Expedient number")}

        />
        <Column
            field="policyType.name"

            // filter
            // filterPlaceholder={t("Search")}
            
            filterMatchMode="contains"
            header={t("Branch")}
            body={(rowData) => policy && policy.policyType ? policy.policyType.name : rowData.policyType ? rowData.policyType.name : ""}
        />
        {policyInsurance && <Column

            // filter
            // filterPlaceholder={t("Search")}
            header={t("Insurance")}
            body={() => policyInsurance.name}
        />}

        <Column
            field="status"
            filter
            filterMatchMode="equals"
            filterElement={(filter) => <FilterDropdownTemplate filter={filter} options={claimStatusOptions}/>}
            header={t("Status")}
            body={({status}) => t(status)}
        />

        {policyUser && <Column

            // filter
            // filterPlaceholder={t("Search")}
            header={t("User")}
            body={() => <Link
                to={"/users/" + policyUser.id}>{policyUser.name} {policyUser.firstSurname} {policyUser.secondSurname}</Link>}
        />}

        <Column
            field="createdAt"
            filter
            filterElement={monthYearFilterTemplate}
            filterMatchMode="contains"
            filterFunction={(value, filter) => {
                const valueMonthYear = value ? value.substring(0, 7) : "";
                const filterMonthYear = filter ? filter.toISOString().substring(0, 7) : "";
                return valueMonthYear === filterMonthYear;
            }}
            header={t("Created")}
            body={({createdAt}) => formatDateUtil(new Date(createdAt))}
        />

        <Column

            // filter
            // filterPlaceholder={t("Search")}
            header={t("Closed")}
            body={({closeDate}) => formatDateUtil(new Date(closeDate))}
        />


        <Column header={t("Actions")} style={{width: "160px"}} body={PoliciesActions}/>
    </DataTable>
}
