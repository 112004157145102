/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 20/09/2023
 * @descriptionThe This component represents a page for viewing a list of proposals. It retrieves the proposals from the API, paginates them, and displays them in a table format. The table includes options for lazy loading to optimize performance. Additionally, it utilizes a utility function, `getUserInfoBy`, to fetch user information for each proposal asynchronously, improving the efficiency of rendering user-related data.
 */
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {API} from "../../queries/api";
import {createFilterParamsUtil} from "../../utils/createFilterParams.util";
import ProposalsTable from "../../components/ProposalsTable/ProposalsTable";
import {checkAndReturnCorrectLazyTableValues} from "../../utils/checkAndReturnCorrectLazyTableValues.util";

export default function ProposalsPage() {
    const [proposals, setProposals] = useState([]);
    const [lazyTable, setLazyTable] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });
    const {t} = useTranslation();

    const getLazyData = async (event) => {
        const {data: {data: proposals, count}} = await API(
            "policy/proposal/list" + createFilterParamsUtil(event, "filter", 10, "proposals") + "&extraFields=policyType"
        );
        setLazyTable({...event, totalRecords: count})

        const proposalsWithUsers = await getUserInfoBy(proposals, "extraFields=advisor")
        setProposals(proposalsWithUsers);
    };

    const onLazy = (event) => {
        const newLazyTable = checkAndReturnCorrectLazyTableValues(lazyTable, event);
        setLazyTable(newLazyTable);
        getLazyData(newLazyTable);
    };


    useEffect(() => {
        getLazyData(lazyTable);
    }, []);
    return (
        <div>

            <div className={"b-box"}>
                <h2>
                    {t("Proposals list")} ({lazyTable.totalRecords})
                </h2>
                <ProposalsTable data={proposals} onLazy={onLazy} lazyTable={lazyTable} isCreationDisabled={true}/>
            </div>
        </div>
    );
}

export const getUserInfoBy = async (array, parameters) => {
    const users = {};
    let userData;
    for (const item of array) {
        if (!users[item.userId]) {
            const { data: user } = await API.get(
                "user/user/view/" + item.userId + (parameters ? "?" + parameters : "")
            );
            userData = user
        } else {
            userData = users[item.userId]
        }
        item.user = userData;
    }

    return array;
}
