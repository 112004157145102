/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 17/01/2024
 * @descriptionThe This component represents a page for managing tasks. It allows users to view, create, and delete tasks. Users can filter tasks based on various criteria such as status, type, ID, alarm date, name, and creation date. Additionally, users can choose to show either all tasks or only their own tasks. The page provides options to create both regular tasks and notes. Users can also view details of each task and perform actions like viewing and removing tasks.
 */
import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState} from "react";
import {API} from "../../queries/api";
import {createFilterParamsUtil} from "../../utils/createFilterParams.util";
import {getUserInfoBy} from "../ProposalsPage/ProposalsPage";
import TasksTable from "../../components/TasksTable/TasksTable";
import {Link, useNavigate} from "react-router-dom";
import {Button} from "primereact/button";
import {GlobalContext} from "../../App";
import {checkAndReturnCorrectLazyTableValues} from "../../utils/checkAndReturnCorrectLazyTableValues.util";
import ActionsOverlayMenu from "../../components/ActionsOverlayMenu/ActionsOverlayMenu";
import {proposalsTypes} from "../../consts/proposalsTypes.const";

export default function TasksPage() {
    const {user} = useContext(GlobalContext);
    const [tasks, setTasks] = useState([]);
    const [lazyTable, setLazyTable] = useState({
        first: 0,
        rows: 10,
        page: 1,
        filters: {
            status: {
                "value": user && (user.rol === "Manager" || user.rol === "SuperAdmin" || user.rol === "Administrator") ? "PENDING" : null,
                "matchMode": "startsWith"
            }, taskTypeId: {
                "matchMode": "equals"
            }, id: {
                "matchMode": "contains"
            }, "alarmAt": {
                "matchMode": "contains"
            }, "name": {
                "matchMode": "contains"
            }, "createdAt": {
                "matchMode": "contains"
            }, "assignedTo.firstName": {
                "matchMode": "contains"
            }, "assignedToId": {
                "matchMode": "equals"
            }
        },
        sortField: null,
        sortOrder: null,
    });

    const navigate = useNavigate()

    const [isShowingAllTasks, setIsShowingAllTasks] = useState(user.rol !== "Manager")
    const {t} = useTranslation();

    const getLazyData = async (event, userId) => {
        const {data: {data: tasks, count}} = await API(
            "task/task/list" + createFilterParamsUtil(event) + "&extraFields=taskType,taskFiles,comments,assignedTo,createdBy" + (userId ? "&filter[userId]=" + userId : "")
        );
        setLazyTable({...event, totalRecords: count})


        const tasksWithUsers = await getUserInfoBy(tasks, "extraFields=advisor")
        setTasks(tasksWithUsers.map((d) => ({
            ...d,
            alarmAt: d.alarmAt ? new Date(d.alarmAt) : t("No date"),
            createdAt: d.createdAt ? new Date(d.createdAt) : t("No date"),
        })));
    };

    const onLazy = (event) => {
        const newLazyTable = checkAndReturnCorrectLazyTableValues(lazyTable, event);
        setLazyTable(newLazyTable);
        getLazyData(newLazyTable);
    };


    const TasksActions = (rowData) => {
        const items = [
            {
                label: t("See"),
                icon: (
                    <span className="me-4 material-symbols-outlined">visibility</span>
                ),
                command: () => navigate("/tasks/" + rowData.id),
            },
            {
                label: t("Remove"),
                icon: <span className="me-4 material-symbols-outlined">delete</span>,
                command: () => onDeleteTask(rowData.id)
            }
        ];
        return <ActionsOverlayMenu items={rowData.proposalId ? [{
            label: t("Carry out"),
            icon: (
                <span className="me-4 material-symbols-outlined">shop</span>
            ),
            command: () => navigate("/proposals/" + rowData.proposalId + (rowData.taskType && rowData.taskType.type === "proposal_finished" ? "?taskId=" + rowData.id: "") + (rowData.taskType ? "#" + proposalsTypes[rowData.taskType.type] : "")),
        }, ...items] : items}/>;
    };

    const onDeleteTask = async (id) => {
        await API.delete("task/task/delete/" + id);
        onLazy(lazyTable)
    }

    const tasksHeader = () => (
        <div className={"d-flex justify-content-end"}>
            {isShowingAllTasks &&
                <Button label={t("Show my tasks")} onClick={() => {
                    setIsShowingAllTasks(false);
                    getLazyData(lazyTable, user.id)
                }}></Button>}
            {!isShowingAllTasks &&
                <Button label={t("Show all tasks")} onClick={() => {
                    setIsShowingAllTasks(true);
                    getLazyData(lazyTable)
                }}></Button>}
            <Link className={"ms-6"} to={"/tasks/new?type=note"}>
                <Button label={t("Create note")}></Button>
            </Link>

            <Link className={"ms-6"} to={"/tasks/new"}>
                <Button label={t("Create task")}></Button>
            </Link>
        </div>
    );

    useEffect(() => {
        getLazyData(lazyTable);
    }, []);
    return (
        <div>

            <div className={"b-box"}>
                <h2>
                    {t("Tasks list")} ({lazyTable.totalRecords})
                </h2>
                <TasksTable data={tasks} onRefresh={() => getLazyData(lazyTable)} onLazy={onLazy} lazyTable={lazyTable}
                            isCreationDisabled={false} tableHeader={tasksHeader} tasksActions={TasksActions}/>
            </div>
        </div>
    );
}
