/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 31/05/2023
 * @descriptionThe This component represents a page for managing insurance policies. It retrieves policy data from the server, including policy type, proposals, and associated company information. The page allows users to view a list of policies with details such as effective date, expiration date, and initial effective date. Users can paginate through the list and perform lazy loading for better performance. The creation of new policies is disabled in this view.
 */
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {API} from "../../queries/api";
import {createFilterParamsUtil} from "../../utils/createFilterParams.util";
import {getUserInfoBy} from "../ProposalsPage/ProposalsPage";
import PoliciesTable from "../../components/PoliciesTable/PoliciesTable";
import {checkAndReturnCorrectLazyTableValues} from "../../utils/checkAndReturnCorrectLazyTableValues.util";

export default function PoliciesPage() {
    const [policies, setPolicies] = useState([]);
    const [lazyTable, setLazyTable] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });

    const {t} = useTranslation();

    const getLazyData = async (event) => {
        const {data: {data: policies, count}} = await API(
            "policy/policy/list" + createFilterParamsUtil(event, "filter", 10, "policies") + "&extraFields=policyType,proposals,company"
        );
        setLazyTable({...event, totalRecords: count})

        const policiesWithUsers = await getUserInfoBy(policies, "extraFields=advisor")
        setPolicies(policiesWithUsers.map((d) => ({
            ...d,
            effectiveDate: new Date(d.effectiveDate),
            expirationDate: new Date(d.expirationDate),
            initialEffectiveDate: new Date(d.initialEffectiveDate),
        })));
    };


    const onLazy = (event) => {
        const newLazyTable = checkAndReturnCorrectLazyTableValues(lazyTable, event);
        setLazyTable(newLazyTable);
        getLazyData(newLazyTable);
    };


    useEffect(() => {

        getLazyData(lazyTable);
    }, []);
    return (
        <div>
            <div className={"b-box"}>
                <h2>
                    {t("Policies list")} ({lazyTable.totalRecords})
                </h2>
                <PoliciesTable data={policies} onLazy={onLazy} lazyTable={lazyTable} isCreationDisabled={true}/>
            </div>
        </div>
    );
}
